<template>
    <onboarding-layout
        :loading="loading || submitting"
        :loading-title="loadingTitle"
        :error-text="errorText"
    >
        <h5
            class="fw-light mb-5"
            v-html="$t('pages.origination.statedIncome.title')"
        />

        <form-container
            id="statedIncomeForm"
            ref="form"
            @on-submit="onSubmit"
        >
            <!-- PRIMARY -->
            <section-header> Your Annual Income </section-header>

            <div class="row g-2 mb-2">
                <div class="col">
                    <form-field
                        @blur="getStateModuleData"
                        v-model="primaryFirstName"
                        data-testid="stated-income-DM-primary-first-name"
                        name="primaryFirstName"
                        class="form-field"
                        text-class="text-muted"
                        :placeholder="$t('components.applicationForm.placeholder.firstName')"
                        :label="$t('components.applicationForm.placeholder.firstName')"
                        validation-rules="required|min:2"
                        autocomplete="given-name"
                    />
                </div>
                <div class="col">
                    <form-field
                        @blur="getStateModuleData"
                        v-model="primaryLastName"
                        data-testid="stated-income-DM-primary-last-name"
                        name="primaryLastName"
                        class="form-field"
                        :placeholder="$t('components.applicationForm.placeholder.lastName')"
                        :label="$t('components.applicationForm.placeholder.lastName')"
                        validation-rules="required|min:2"
                        autocomplete="family-name"
                    />
                </div>
                <span
                    class="small text-gray-400 mt-1"
                    v-show="primaryFirstName || primaryLastName"
                >Use your legal name from a valid state ID or passport</span>
            </div>

            <form-field-currency
                v-model="primaryStatedIncome"
                data-testid="stated-income-DM-primary-input"
                :label="$t('pages.origination.statedIncome.placeholder')"
                :placeholder="$t('pages.origination.statedIncome.placeholder')"
                validation-rules="required|currency:20000"
                name="income"
            />

            <div class="row g-0 mt-4">
                <div class="col pe-1 position-relative d-flex">
                    <list-button
                        v-model="inJointForm"
                        data-testid="stated-income-DM-joint-option"
                        class="fw-bold small"
                        :class="inJointForm ? 'selected' : ''"
                        title="Joint"
                        image-src="origination/Household.svg"
                        @click="inJointForm = true"
                    />
                    <img
                        v-if="inJointForm"
                        class="position-absolute"
                        style="bottom: 16px; right: 8px"
                        width="32px;"
                        src="@/assets/images/origination/checkmark.svg"
                        alt="checkmark"
                    >
                </div>
                <div class="col ps-1 position-relative d-flex">
                    <list-button
                        v-model="inJointForm"
                        data-testid="stated-income-DM-individual-option"
                        class="fw-bold small"
                        :class="!inJointForm ? 'selected' : ''"
                        title="Individual"
                        image-src="origination/Individual.svg"
                        @click="inJointForm = false"
                    />
                    <img
                        v-if="!inJointForm"
                        class="position-absolute"
                        style="bottom: 16px; right: 0"
                        width="32px;"
                        src="@/assets/images/origination/checkmark.svg"
                        alt="checkmark"
                    >
                </div>
            </div>

            <!-- CO-APPLICANT -->
            <div v-if="inJointForm">
                <section-header class="mt-2">
                    Joint Applicant
                </section-header>
                <div class="row g-2 mb-2">
                    <div class="col">
                        <form-field
                            @blur="getStateModuleData"
                            v-model="secondaryFirstName"
                            data-testid="stated-income-DM-secondary-first-name"
                            name="secondaryFirstName"
                            class="form-field"
                            :label="$t('components.applicationForm.placeholder.firstName')"
                            :placeholder="$t('components.applicationForm.placeholder.firstName')"
                            validation-rules="required|min:2"
                        />
                    </div>
                    <div class="col">
                        <form-field
                            @blur="getStateModuleData"
                            v-model="secondaryLastName"
                            data-testid="stated-income-DM-secondary-last-name"
                            name="secondaryLastName"
                            class="form-field"
                            :label="$t('components.applicationForm.placeholder.lastName')"
                            :placeholder="$t('components.applicationForm.placeholder.lastName')"
                            validation-rules="required|min:2"
                        />
                    </div>
                    <span
                        class="small text-gray-400 mt-1"
                        v-show="secondaryFirstName || secondaryLastName"
                    >Use applicant's legal name from a valid state ID or passport</span>
                </div>

                <form-field-currency
                    v-model="secondaryStatedIncome"
                    data-testid="stated-income-DM-secondary-input"
                    :label="$t('pages.origination.statedIncome.placeholder')"
                    :placeholder="$t('pages.origination.statedIncome.placeholder')"
                    name="secondaryIncome"
                    validation-rules="required|currency:10000"
                />
            </div>

            <state-form-module
                ref="stateModules"
                class="mt-2"
                :data="stateModules"
            />

            <form-button
                type="button"
                data-testid="stated-income-DM-continue-button"
                label="Check Your Offer"
                class="mt-4"
                :block="true"
                :submitting="submitting"
                :disabled="ctaButtonDisabled"
                @click="onSubmit"
                event-name="click_button_submit_stated_income_form"
            />
        </form-container>

        <safe-credit-score
            :safe-credit-score-text="$t('pages.marketing.landing.newSafeCreditScore')"
            class="mt-1 mb-0"
        />

        <div class="d-none d-md-block mt-3">
            <p
                class="text-muted small text-start mb-2"
                v-html="$t('components.applicationForm.legal.softPullAndFloodAgreementsHtml')"
            />

            <p class="text-muted small text-start mb-2">
                For more important information about our Aven Home Cards:
                <a
                    @click="getDocument(LegalDocumentTypes.pricingAndTerms)"
                    href="#"
                > Pricing and Terms </a> and
                <a
                    @click="getDocument(LegalDocumentTypes.earlyHELOCDisclosure)"
                    href="#"
                > Early HELOC Disclosure </a>
            </p>

            <p class="text-muted small text-start mb-2">
                {{ $t('pages.origination.statedIncome.legal') }}
            </p>
            <p class="text-muted small text-start mt-2">
                {{ $t('components.applicationForm.legal.jointDisclaimer') }}
            </p>
            <p class="text-muted small text-start mt-2">
                {{ $t('components.applicationForm.legal.incomeDisclaimer') }}
            </p>
        </div>
        <template #sidebar>
            <ul class="list-unstyled list-row">
                <joint-list-item />
                <feedback-list-item />
            </ul>
            <div class="d-block d-md-none">
                <p
                    class="text-muted small text-start mb-2"
                    v-html="$t('components.applicationForm.legal.softPullAndFloodAgreementsHtml')"
                />

                <p class="text-muted small text-start mb-2">
                    For more important information about our Aven Home Cards:
                    <a
                        @click="getDocument(LegalDocumentTypes.pricingAndTerms)"
                        href="#"
                    > Pricing and Terms </a> and
                    <a
                        @click="getDocument(LegalDocumentTypes.earlyHELOCDisclosure)"
                        href="#"
                    > Early HELOC Disclosure </a>
                </p>

                <p class="text-muted small text-start mb-2">
                    {{ $t('pages.origination.statedIncome.legal') }}
                </p>
                <p class="text-muted small text-start mt-2">
                    {{ $t('components.applicationForm.legal.jointDisclaimer') }}
                </p>
            </div>
        </template>
    </onboarding-layout>
</template>

<script>
    import { startCase, toLower } from 'lodash'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import OnboardingLayout from '@/layouts/Onboarding'
    import FormButton from '@/components/base/FormButton'
    import FormContainer from '@/components/base/FormContainer'
    import FormFieldCurrency from '@/components/base/FormFieldCurrency'
    import FormField from '@/components/base/FormField'
    import SectionHeader from '@/components/SectionHeader'
    import originationMixin from '@/mixins/originationMixin'
    import { createCoApplicantLead } from '@/services/auth'
    import { logger } from '@/utils/logger'
    import { sessionStorageKey, appSessionStorage } from '@/utils/storage'
    import { Flows, getNextRoute } from '@/flow/flowController'
    import { updateStatedIncome } from '@/services/loanApplication'
    import { getPrefilledInformationForMailerLead, LegalDocumentTypes, postUpdateApplicantFields } from '@/services/api'
    import { experimentsMixin } from '@/mixins/experimentsMixin'
    import { getDocumentForApplicantAndOpen } from '@/utils/document'
    import JointListItem from '@/components/onboarding/JointListItem'
    import ListButton from '@/components/ListButton'
    import SafeCreditScore from '@/components/SafeCreditScore'
    import authMixin from '@/mixins/authMixin'
    import StateFormModule from '../../components/state/StateFormModule'
    import { getStatePageModules } from '../../services/api'
    import FeedbackListItem from '@/components/onboarding/FeedbackListItem'

    export default {
        components: {
            FeedbackListItem,
            SafeCreditScore,
            ListButton,
            JointListItem,
            SectionHeader,
            FormField,
            'onboarding-layout': OnboardingLayout,
            'form-button': FormButton,
            'form-container': FormContainer,
            'form-field-currency': FormFieldCurrency,
            'state-form-module': StateFormModule,
        },
        mixins: [originationMixin, experimentsMixin, authMixin],
        data: function () {
            return {
                LegalDocumentTypes,
                loading: true,
                inJointForm: true,
                primaryStatedIncome: '',
                secondaryStatedIncome: '',
                loadingTitle: 'Getting things ready...',
                stateModules: {},
                ctaButtonDisabled: false,
            }
        },
        mounted: async function () {
            appSessionStorage.setItem(sessionStorageKey.currentFlow, Flows.mailerOrigination)
            await this.getPrefilledInfo()
            await this.getStateModuleData()

            // These are the dynamically generated legal docs the applicant can view on this page.
            const docsToGenerate = [LegalDocumentTypes.pricingAndTerms, LegalDocumentTypes.earlyHELOCDisclosure]
            // Purposefully not awaiting the results here because these docs are generated for
            // our record-keeping purposes and not strictly required for the UI
            // noinspection ES6MissingAwait
            this.tryGenerateAndSaveLegalDocuments(docsToGenerate)

            this.$logEvent('view_stated_income')
        },
        computed: {
            isApplicantDuplicated() {
                const primaryFilledIn = this.primaryFirstName && this.primaryLastName
                const primaryNamesSameAsSecondary = toLower(this.primaryFirstName) === toLower(this.secondaryFirstName) && toLower(this.primaryLastName) === toLower(this.secondaryLastName)
                return this.inJointForm && primaryFilledIn && primaryNamesSameAsSecondary
            },
        },
        watch: {
            inJointForm: function () {
                this.getStateModuleData()
            },
        },
        methods: {
            scrollToTop() {
                document.body.scrollTop = 0 // For Safari
                document.documentElement.scrollTop = 0
            },
            async getStateModuleData() {
                this.ctaButtonDisabled = true
                try {
                    // Validate applicant name and co applicant name (if applicable) are present beforehand
                    if (!this.primaryFirstName || !this.primaryLastName || (this.inJointForm && (!this.secondaryFirstName || !this.secondaryLastName))) {
                        this.stateModules = {}
                        return
                    }

                    const partialApplicantInfo = {
                        firstName: this.primaryFirstName,
                        lastName: this.primaryLastName,
                    }

                    const partialCoApplicantInfo = this.inJointForm
                        ? {
                            firstName: this.secondaryFirstName,
                            lastName: this.secondaryLastName,
                        }
                        : undefined

                    const stateModulesResponse = await getStatePageModules('PersonalInfo', null, partialApplicantInfo, partialCoApplicantInfo)
                    this.stateModules = stateModulesResponse.data.payload
                } finally {
                    this.ctaButtonDisabled = false
                }
            },
            getPrefilledInfo: async function () {
                try {
                    const prefilledInfoResponse = await getPrefilledInformationForMailerLead(appSessionStorage.getItem(sessionStorageKey.inviteCode))
                    if (prefilledInfoResponse.data.success) {
                        const prefilledInformation = prefilledInfoResponse.data.payload
                        this.primaryFirstName = startCase(toLower(prefilledInformation.firstName))
                        this.primaryLastName = startCase(toLower(prefilledInformation.lastName))
                        this.secondaryFirstName = startCase(toLower(prefilledInformation.secondaryFirstName))
                        this.secondaryLastName = startCase(toLower(prefilledInformation.secondaryLastName))
                        appSessionStorage.setItem(sessionStorageKey.firstName, this.primaryFirstName)
                        appSessionStorage.setItem(sessionStorageKey.lastName, this.primaryLastName)
                    } else {
                        logger.error('No address data found for mailer lead')
                    }
                    this.loading = false
                } catch (e) {
                    this.errorText = ApiErrorHandler(e)
                }
            },
            onSubmit: async function () {
                if (this.loading) {
                    return
                }
                this.loading = true

                // Check if form is valid
                const isValid = await this.$refs.form.$refs.observer.validate()
                const isApplicantIncomeInvalid = isNaN(parseInt(this.primaryStatedIncome))
                const isCoApplicantIncomeInvalid = this.inJointForm && isNaN(parseInt(this.secondaryStatedIncome))
                if (!isValid || isApplicantIncomeInvalid || isCoApplicantIncomeInvalid) {
                    this.loading = false
                    return
                }

                if (this.isApplicantDuplicated) {
                    this.errorText = 'It looks like you entered the same person twice. If you would like to apply as an individual, please select the individual option.'

                    // 1. scroll validation errors into view
                    const el = document.querySelector('.is-invalid:first-of-type')
                    el?.scrollIntoView()

                    if (this.errorText) {
                        // 2. if there's errorText, scroll to the top
                        this.scrollToTop()
                    }

                    this.loading = false
                    return
                }

                try {
                    this.storeUserInfo()

                    await postUpdateApplicantFields({
                        firstName: this.primaryFirstName,
                        lastName: this.primaryLastName,
                    })
                    await updateStatedIncome(this.primaryStatedIncome)

                    if (this.inJointForm) {
                        logger.info('Creating co-applicant and submitting co-applicant info')

                        if (!appSessionStorage.getItem(sessionStorageKey.coApplicantJwtTokens)) {
                            const authResponse = await createCoApplicantLead()
                            if (!authResponse.data.success) {
                                await this.handleAuthErrors(authResponse)
                                return
                            }
                        }

                        await postUpdateApplicantFields(
                            {
                                firstName: this.secondaryFirstName,
                                lastName: this.secondaryLastName,
                            },
                            true
                        )

                        await updateStatedIncome(this.secondaryStatedIncome, true)
                    }

                    await this.$refs.stateModules.sendValuesToBackend()

                    return await this.$router.push(getNextRoute(this.$router))
                } catch (error) {
                    this.errorText = ApiErrorHandler(error)
                }
                this.loading = false
            },
            storeUserInfo: function () {
                // TODO: Maybe use vuex and retrieve from backend on refresh
                appSessionStorage.setItem(sessionStorageKey.firstName, this.primaryFirstName)
                appSessionStorage.setItem(sessionStorageKey.lastName, this.primaryLastName)
                appSessionStorage.setItem(sessionStorageKey.statedIncome, this.primaryStatedIncome)

                if (this.inJointForm) {
                    appSessionStorage.setItem(sessionStorageKey.coApplicantFirstName, this.secondaryFirstName)
                    appSessionStorage.setItem(sessionStorageKey.coApplicantLastName, this.secondaryLastName)
                    appSessionStorage.setItem(sessionStorageKey.coApplicantStatedIncome, this.secondaryStatedIncome)
                }
            },
            getDocument: async function (docType) {
                try {
                    this.loadingTitle = 'Loading Document...'
                    this.loading = true
                    this.$logEvent('click_link_get_document', { docType })
                    await getDocumentForApplicantAndOpen(docType)
                } catch (e) {
                    logger.error(`failed to open pdfs document`, e)
                    this.errorText = ApiErrorHandler(e)
                }
                this.loading = false
                this.loadingTitle = 'Getting things ready...'
            },
        },
    }
</script>
