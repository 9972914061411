<template>
    <onboarding-layout
        :loading="loading"
        :loading-title="loadingTitle"
        :error-text="errorText"
        step-title="Step 1 of 3"
    >
        <h5
            class="fw-light"
            v-html="title"
        />
        <form-container
            :v-if="!loading"
            id="applicationForm"
            ref="basicInfoForm"
            @on-submit="onSubmit"
            @onError="onError"
        >
            <div class="bg-light rounded-2 w-100 p-1">
                <span class="pe-1">
                    <img
                        src="@/assets/images/global/stopwatch.svg"
                        alt="Very Fast"
                    >
                </span>
                <span class="small text-gray-400">For most, this takes less than 60 seconds</span>
            </div>
            <!-- PRIMARY APPLICANT -->
            <section-header class="mt-6">
                {{ primaryHeading }}
            </section-header>

            <form-field
                class="mb-2"
                v-model="primaryEmail"
                data-testid="personal-info-DM-primary-email-input"
                name="primaryEmail"
                :placeholder="$t('components.applicationForm.placeholder.email')"
                :label="$t('components.applicationForm.placeholder.email')"
                validation-rules="required|email"
                input-type="email"
                inputmode="email"
            />

            <form-field-ssn
                v-model="primarySSN"
                data-testid="personal-info-DM-primary-last-4-ssn-input"
                name="primarySSN"
                class="mb-2"
                last-four-only
            />

            <form-field-date
                class="mb-2"
                v-model="primaryDOB"
                data-testid="personal-info-DM-primary-dob-input"
                name="primaryDOB"
                validation-rules="required|dateRule|ageRule"
                :label="$t('components.applicationForm.placeholder.dateOfBirth')"
                :placeholder="$t('components.applicationForm.placeholder.dateOfBirth')"
                :focus-placeholder="$t('components.applicationForm.placeholder.dob')"
            />

            <form-radio-group
                class="radio-left-aligned"
                :options="maritalStatusOptions"
                name="primaryMaritalStatusInput"
                v-model="primaryMaritalStatus"
                data-testid="personal-info-DM-primary-marital-status"
                validation-rules="required"
                :inline="true"
            />

            <!-- CO-APPLICANT -->
            <div v-if="hasCoApplicant">
                <section-header class="mt-4">
                    {{ secondaryHeading }}
                </section-header>

                <form-field-ssn
                    v-model="secondarySSN"
                    data-testid="personal-info-DM-secondary-last-4-ssn-input"
                    name="secondarySSN"
                    class="mb-2"
                    last-four-only
                />

                <form-field-date
                    class="mb-2"
                    v-model="secondaryDOB"
                    data-testid="personal-info-DM-secondary-dob-input"
                    name="secondaryDOB"
                    validation-rules="required|dateRule|ageRule"
                    :label="$t('components.applicationForm.placeholder.dateOfBirth')"
                    :placeholder="$t('components.applicationForm.placeholder.dateOfBirth')"
                    :focus-placeholder="$t('components.applicationForm.placeholder.dob')"
                />

                <form-radio-group
                    class="radio-left-aligned"
                    :options="maritalStatusOptions"
                    name="secondaryMaritalStatusInput"
                    v-model="secondaryMaritalStatus"
                    data-testid="personal-info-DM-secondary-marital-status"
                    validation-rules="required"
                    :inline="true"
                />
            </div>

            <section-header class="mt-4">
                Address
            </section-header>

            <div :key="!!initAddressData">
                <form-field-address
                    ref="propertyAddressField"
                    @on-change="onPropertyAddressInputChange"
                    @on-clear="onClearPropertyAddress"
                    :initial-value="initAddressData"
                    initial-input-type="auto"
                />
            </div>

            <form-radio-group
                class="radio-left-aligned fw-bold"
                :prompt="promptNOO"
                :options="residenceTypeOptions"
                name="residence-type-input"
                v-model="residenceType"
                validation-rules="required"
                :inline="true"
            />
            <div
                class="invalid-feedback is-invalid"
                :style="primaryResidenceError ? 'display: block' : ''"
                data-testid="primary-residence-error"
            >
                {{ primaryResidenceError }}
            </div>

            <!-- Personal Address -->
            <section-header
                class="mt-4"
                v-if="isPersonalAddressBoxVisible"
            >
                Address of Primary Residency
            </section-header>

            <form-field-address
                v-if="isPersonalAddressBoxVisible"
                ref="personalAddressField"
                @on-change="onPersonalAddressInputChange"
                @on-clear="onClearPersonalAddress"
                initial-input-type="auto"
            />

            <form-button
                class="mt-4"
                data-testid="personal-info-DM-continue-button"
                image-src="global/web_16_lock.svg"
                :label="buttonCta"
                :submitting="submitting"
                type="submit"
                event-name="click_button_submit_personal_info_co_applicant_form"
                :event-props="{ withCoApplicant: hasCoApplicant }"
            />

            <safe-credit-score
                :safe-credit-score-text="$t('pages.marketing.landing.newSafeCreditScore')"
                class="mt-1 mb-0"
            />

            <div class="d-none d-md-block text-start mt-3">
                <p
                    class="text-muted small text-start mb-2"
                    v-html="$t('components.applicationForm.legal.softPullAndFloodAgreementsHtml')"
                />
                <p
                    class="text-start text-muted mt-2 mb-0 small"
                    v-html="$t('pages.origination.ssnVerification.legalText')"
                />
                <p
                    class="small text-muted text-start mt-2 mb-0"
                    v-html="$t(`experiments.${heraclesParameter.EXPERIMENT_NAME}.prequalFooter`)"
                />
                <p
                    v-if="statedUsage === StatedUsageType.BALANCE_TRANSFER"
                    class="small text-muted text-start mt-2 mb-0"
                >
                    2. Our APR is 3.49-10.99%. The average for other cards in the US for people with good credit is over 18% APR. Source: "Average Credit Card Interest Rates" wallethub.com on 03.21.22
                    showing the average rate for credit cards in the US for people with good credit is 18.98% APR.
                </p>
                <p
                    v-if="statedUsage === StatedUsageType.BALANCE_TRANSFER"
                    class="small text-muted text-start mt-2 mb-0"
                >
                    3. Based on transferring $45,735 from a 14% APR personal loan (estimated APR from monthly payments on bureau data) to a 5.74% APR Aven Card. Assumes outstanding balances remains
                    the same throughout the year.
                </p>
            </div>
        </form-container>
        <template #sidebar>
            <ul class="list-unstyled list-row">
                <offer-info-card
                    v-if="preQualificationOffer"
                    title="Your PreQual Offer"
                    :apr="preQualificationOffer.apr"
                    :credit-limit="preQualificationOffer.lineSize"
                />
                <save-up-to-list-item v-if="statedUsage === StatedUsageType.BALANCE_TRANSFER" />
                <unlimited-cashback-list-item v-if="statedUsage === StatedUsageType.HOME_IMPROVEMENT" />
                <social-proof-list-item :review-index="8" />
                <feedback-list-item />
            </ul>
            <div class="d-block d-md-none text-start">
                <p
                    class="text-muted small text-start mb-2"
                    v-html="$t('components.applicationForm.legal.softPullAndFloodAgreementsHtml')"
                />
                <p
                    class="text-start text-muted mt-2 mb-0 small"
                    v-html="$t('pages.origination.ssnVerification.legalText')"
                />
                <p
                    class="small text-muted text-start mt-2 mb-0"
                    v-html="$t(`experiments.${heraclesParameter.EXPERIMENT_NAME}.prequalFooter`)"
                />
                <p
                    v-if="statedUsage === StatedUsageType.BALANCE_TRANSFER"
                    class="small text-muted text-start mt-2 mb-0"
                    v-html="$t('pages.origination.ssnVerification.legalText')"
                >
                    2. Our APR is 3.49-10.99%. The average for other cards in the US for people with good credit is over 20% APR. (Source: "What is the average credit card rate?") wallethub.com on
                    4.6.20, showing the average rate for credit cards in the US for people with good credit is 20.24% APR.
                </p>
                <p
                    v-if="statedUsage === StatedUsageType.BALANCE_TRANSFER"
                    class="small text-muted text-start mt-2 mb-0"
                >
                    3. Based on transferring $45,735 from a 14% APR personal loan (estimated APR from monthly payments on bureau data) to a 5.74% APR Aven Card. Assumes outstanding balances remains
                    the same throughout the year.
                </p>
            </div>
        </template>
    </onboarding-layout>
</template>

<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import { logger, inspect } from '@/utils/logger'
    import { sessionStorageKey, appSessionStorage } from '@/utils/storage'
    import { getPrefilledInformationForMailerLead, MaritalStatus } from '@/services/api'
    import originationMixin from '@/mixins/originationMixin'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { isSafariPrivateBrowsing } from '@/utils/parseUserAgents'
    import { sharedPagePaths } from '@/routes/sharedRoutes'
    import FormContainer from '@/components/base/FormContainer'
    import SectionHeader from '@/components/SectionHeader'
    import FormField from '@/components/base/FormField'
    import FormFieldDate from '@/components/base/FormFieldDate'
    import FormFieldSsn from '@/components/base/FormFieldSsn'
    import FormFieldAddress from '@/components/base/FormFieldAddress'
    import FormButton from '@/components/base/FormButton'
    import FormRadioGroup from '@/components/base/FormRadioGroup'
    import personalInfoMixin from '@/mixins/personalInfoMixin'
    import startCase from 'lodash/startCase'
    import toLower from 'lodash/toLower'
    import { i18n } from '@/utils/i18n'
    import { Flows, getNextRoute } from '@/flow/flowController'
    import { toUpper } from 'lodash'
    import { experimentsMixin } from '@/mixins/experimentsMixin'
    import SafeCreditScore from '@/components/SafeCreditScore'
    import OfferInfoCard from '@/components/onboarding/OfferInfoCard'
    import computePreQualificationOfferFromStorageMixin from '@/mixins/computePreQualificationOfferFromStorageMixin'
    import SocialProofListItem from '@/components/onboarding/SocialProofListItem'
    import SaveUpToListItem from '@/components/onboarding/SaveUpToListItem'
    import UnlimitedCashbackListItem from '@/components/onboarding/UnlimitedCashbackListItem'
    import LogRocket from 'logrocket'
    import { ResidenceType, StatedUsageType } from '../../services/api'
    import { FLAG_NOO_IS_DISABLED } from '../../utils/flags'
    import FeedbackListItem from '@/components/onboarding/FeedbackListItem'

    export default {
        components: {
            FeedbackListItem,
            UnlimitedCashbackListItem,
            SaveUpToListItem,
            SafeCreditScore,
            FormButton,
            FormFieldDate,
            FormFieldAddress,
            FormField,
            SectionHeader,
            FormContainer,
            FormFieldSsn,
            FormRadioGroup,
            'onboarding-layout': OnboardingLayout,
            'offer-info-card': OfferInfoCard,
            'social-proof-list-item': SocialProofListItem,
        },
        mixins: [originationMixin, personalInfoMixin, experimentsMixin, computePreQualificationOfferFromStorageMixin],
        data() {
            return {
                StatedUsageType,
                loading: true,
                loadingTitle: i18n.t('global.loadingMessage.verifying'),
                residenceType: '',
                buttonCta: i18n.t('global.cta.continue'),
                initAddressData: null,
                propertyAddressData: null,
                personalAddressData: null,
                primaryEmail: '',
                primarySSN: '',
                primaryDOB: '',
                primaryMaritalStatus: '',
                secondarySSN: '',
                secondaryDOB: '',
                secondaryMaritalStatus: '',
                statedUsage: appSessionStorage.getItem(sessionStorageKey.statedUsage),
                maritalStatusOptions: [
                    { name: i18n.t('pages.origination.personalInfo.maritalStatus.married'), value: MaritalStatus.MARRIED },
                    { name: i18n.t('pages.origination.personalInfo.maritalStatus.unmarried'), value: MaritalStatus.UNMARRIED },
                    { name: i18n.t('pages.origination.personalInfo.maritalStatus.separated'), value: MaritalStatus.SEPARATED },
                ],
                residenceTypeOptions: [
                    { name: i18n.t('components.applicationForm.residenceType.primary'), value: ResidenceType.PRIMARY },
                    { name: i18n.t('components.applicationForm.residenceType.secondary'), value: ResidenceType.SECONDARY },
                ],
                primaryResidenceError: '',
            }
        },
        computed: {
            title() {
                const hasViewedPreQual = !appSessionStorage.getItem(sessionStorageKey.preQualificationFailureCode)
                return hasViewedPreQual
                    ? 'Just 3 quick steps to lock things in. This <span class="text-underline">won’t</span> affect your credit score.'
                    : 'Almost there! We just need the basics...<br>This <span class="text-underline">won’t</span> affect your credit score.'
            },
            promptNOO() {
                return i18n.t('components.applicationForm.residenceType.question')
            },
            secondaryHeading() {
                const firstName = toUpper(appSessionStorage.getItem(sessionStorageKey.coApplicantFirstName))
                return `${firstName}’s Information`
            },
            primaryHeading() {
                const firstName = toUpper(appSessionStorage.getItem(sessionStorageKey.firstName))
                return this.hasCoApplicant ? `${firstName}’s Information` : 'Your Information'
            },
            isApplicantDuplicated() {
                return this.hasCoApplicant && this.primaryDOB && this.primarySSN && this.primaryDOB === this.secondaryDOB && this.primarySSN === this.secondarySSN
            },
            isPrimaryResidence() {
                return this.residenceType === ResidenceType.PRIMARY
            },
            isPersonalAddressBoxVisible() {
                if (FLAG_NOO_IS_DISABLED) {
                    return false
                }
                return this.residenceType && !this.isPrimaryResidence
            },
        },
        mounted: async function () {
            appSessionStorage.setItem(sessionStorageKey.currentFlow, Flows.mailerOrigination)

            if (isSafariPrivateBrowsing()) {
                return await this.$router.replace({ path: sharedPagePaths.THANKS, query: { reason: 'privateBrowsing' } })
            }

            try {
                const prefilledInfoResponse = await getPrefilledInformationForMailerLead(appSessionStorage.getItem(sessionStorageKey.inviteCode))
                if (prefilledInfoResponse.data.success) {
                    const prefilledInformation = prefilledInfoResponse.data.payload

                    prefilledInformation.addressData.addressComponents.country = 'USA'
                    prefilledInformation.addressData.addressComponents.addressUnit = startCase(toLower(prefilledInformation.addressData.addressComponents.addressUnit))
                    prefilledInformation.addressData.addressComponents.addressPostalCode = prefilledInformation.addressData.addressComponents.addressPostalCode.slice(0, 5)

                    this.initAddressData = {
                        inputType: 'automatic',
                        addressComponents: prefilledInformation.addressData.addressComponents,
                    }
                } else {
                    logger.error('No address info for mailer lead returned')
                }

                this.loading = false
                this.$logEvent('view_personal_info')
            } catch (e) {
                this.errorText = ApiErrorHandler(e)
            }
        },
        watch: {
            residenceType: function () {
                this.primaryResidenceError = ''
            },
        },
        methods: {
            onError(errorText) {
                this.errorText = errorText
                this.scrollToTop()
            },
            scrollToTop() {
                document.body.scrollTop = 0 // For Safari
                document.documentElement.scrollTop = 0
            },
            onPropertyAddressInputChange(value) {
                logger.log(`property address input change: ${inspect(value)}`)
                this.propertyAddressData = value
            },
            onClearPropertyAddress() {
                this.propertyAddressData = null
                this.residenceType = ''
            },
            onPersonalAddressInputChange(value) {
                logger.log(`personal address input change: ${inspect(value)}`)
                this.personalAddressData = value
            },
            onClearPersonalAddress() {
                this.personalAddressData = null
            },
            onSubmit: async function () {
                if (this.loading) {
                    console.log('Still loading, refusing to submit')
                    return
                }

                // Check if base is valid
                let isValid = await this.$refs.basicInfoForm.$refs.observer.validate()
                if (this.$refs.propertyAddressField.useAutocomplete) {
                    isValid = isValid && (await this.$refs.propertyAddressField.validateAutocompleteAddress())
                }
                if (this.isPersonalAddressBoxVisible && this.$refs.personalAddressField.useAutocomplete) {
                    isValid = isValid && (await this.$refs.personalAddressField.validateAutocompleteAddress())
                }

                const isNOOError = FLAG_NOO_IS_DISABLED && !this.isPrimaryResidence
                this.primaryResidenceError = isNOOError ? 'We currently only support primary residences. Please confirm this is your primary residence or use a different address.' : ''

                this.errorText = this.isApplicantDuplicated ? 'It looks like you entered the same person twice. If you would like to apply as an individual, please select the individual option.' : ''

                if (!isValid || !this.residenceType || isNOOError || this.isApplicantDuplicated || !this.propertyAddressData) {
                    const el = document.querySelector('.is-invalid:first-of-type')
                    el?.scrollIntoView()

                    if (this.errorText) {
                        // 2. if there's errorText, scroll to the top
                        this.scrollToTop()
                    }

                    this.loading = false
                    return
                }

                this.loading = true

                if (this.isPrimaryResidence) {
                    // Addresses are the same
                    this.personalAddressData = this.propertyAddressData
                }

                this.saveDataToStorage()

                try {
                    const primaryApplicantInfo = {
                        emailAddress: this.primaryEmail,
                        propertyAddressData: this.propertyAddressData,
                        personalAddressData: this.personalAddressData,
                        ssn: this.primarySSN,
                        applicantDOB: this.primaryDOB,
                        maritalStatus: this.primaryMaritalStatus,
                        residenceType: this.residenceType,
                    }

                    const didPrimaryUnderwritingInfoChange = await this.getDidUnderwritingInfoChange(primaryApplicantInfo)

                    let isSuccessful = await this.submitBasicInfo(primaryApplicantInfo, false /*isCoApplicant*/)
                    if (!isSuccessful) {
                        return
                    }

                    if (this.hasCoApplicant) {
                        isSuccessful = await this.submitBasicInfo(
                            {
                                propertyAddressData: this.propertyAddressData,
                                personalAddressData: this.personalAddressData,
                                ssn: this.secondarySSN,
                                applicantDOB: this.secondaryDOB,
                                maritalStatus: this.secondaryMaritalStatus,
                                residenceType: this.residenceType,
                            },
                            /* isCoApplicant */ true
                        )
                        if (!isSuccessful) {
                            return
                        }
                    }

                    // generally if coApp we will go straight to this page without ever running PQ (since won't have coApp ssn for PQ).
                    // however if applicant went back and added coApp to on mailerOrigination/statedIncome, it's possible PQ would have already run
                    // bottom line - isUnderwritingInfoUnchanged = false if there is a coApplicant since we will never be able to successfully run first DM PQ with coApp
                    // since we do not have the coApp's ssn.
                    if (!didPrimaryUnderwritingInfoChange && !this.hasCoApplicant) appSessionStorage.setItem(sessionStorageKey.isUnderwritingInfoUnchanged, 'true')

                    this.identifyLogRocketUser()

                    return await this.$router.push(getNextRoute(this.$router))
                } catch (e) {
                    this.errorText = ApiErrorHandler(e)
                } finally {
                    this.loading = false
                }
            },
            saveDataToStorage: function () {
                const basicInfo = {
                    propertyAddressData: this.propertyAddressData,
                    personalAddressData: this.personalAddressData,
                    residenceType: this.residenceType,
                }
                appSessionStorage.setItem(sessionStorageKey.basicInfo, JSON.stringify(basicInfo))

                appSessionStorage.setItem(sessionStorageKey.applicantMaritalStatus, this.primaryMaritalStatus)
                appSessionStorage.setItem(sessionStorageKey.coApplicantMaritalStatus, this.secondaryMaritalStatus)
            },
            updateBasicInfo: function (value, isCoApplicant) {
                if (!isCoApplicant) {
                    this.primaryDOB = value.applicantDOB || this.primaryDOB
                } else {
                    this.secondaryDOB = value.applicantDOB || this.secondaryDOB
                }

                this.primaryEmail = value.emailAddress || this.primaryEmail
                this.$refs.propertyAddressField.loadAddressFormData(value.propertyAddressData)
                if (this.isPersonalAddressBoxVisible) {
                    this.$refs.personalAddressField.loadAddressFormData(value.personalAddressData)
                }
            },
            identifyLogRocketUser: function () {
                try {
                    const sessionId = appSessionStorage.getItem(sessionStorageKey.sessionId)
                    LogRocket.identify(sessionId, {
                        name: `${this.primaryFirstName} ${this.primaryLastName}`,
                        phone: this.phone,
                        email: this.email,
                    })
                } catch (error) {
                    logger.info(`LogRocket failed to identify: ${inspect(error)}`)
                }
            },
        },
    }
</script>

<style scoped>
    .radio-left-aligned {
        margin-left: -1.5em;
    }
</style>
