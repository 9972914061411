<template>
    <onboarding-layout
        :title="title"
        :loading="loading"
        :loading-title="$t('global.loadingMessage.verifying')"
        :error-text="errorText"
        :show-title-bar="false"
    >
        <div class="mb-2 mt-1">
            <form-container
                id="ssnVerificationForm"
                ref="ssnVerificationForm"
                @on-submit="onSubmit"
            >
                <form-field-ssn
                    v-model="ssn"
                    name="ssn"
                    class="form-field"
                />
                <form-button
                    :submitting="submitting"
                    :disabled="ssn.length !== 9"
                    type="submit"
                    class="mt-2"
                    label="Continue"
                    event-name="click_button_submit_ssn_verification_form"
                />
            </form-container>
            <p
                class="text-center fw-bold mt-2 mb-1"
                v-html="$t('components.applicationForm.legal.doesNotAffectFicoHtml')"
            />
        </div>
    </onboarding-layout>
</template>

<script>
    import assert from 'assert'
    import { startCase, toLower } from 'lodash'
    import OnboardingLayout from '@/layouts/Onboarding'
    import FormFieldSsn from '@/components/base/FormFieldSsn'
    import FormButton from '@/components/base/FormButton'
    import FormContainer from '@/components/base/FormContainer'
    import originationMixin from '@/mixins/originationMixin'
    import { getNextRoute } from '@/flow/flowController'
    import { postUpdateApplicantFields } from '@/services/api'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { appSessionStorage, sessionStorageKey } from '@/utils/storage'

    export default {
        components: {
            'onboarding-layout': OnboardingLayout,
            'form-container': FormContainer,
            'form-field-ssn': FormFieldSsn,
            'form-button': FormButton,
        },
        mixins: [originationMixin],
        data: function () {
            return {
                ssn: '',
            }
        },
        props: {
            isCoApplicant: {
                type: Boolean,
                default: false,
            },
        },
        mounted: function () {
            this.$logEvent('view_ssn_verification')
        },
        computed: {
            title() {
                if (this.isCoApplicant) {
                    // Co-applicant
                    const firstName = startCase(toLower(appSessionStorage.getItem(sessionStorageKey.coApplicantFirstName)))
                    return `<span class="fw-bold">${firstName}</span>, it looks like we need your SSN to confirm your identity `
                } else if (appSessionStorage.getItem(sessionStorageKey.coApplicantJwtTokens)) {
                    // Primary applicant with a co-applicant
                    const firstName = startCase(toLower(appSessionStorage.getItem(sessionStorageKey.firstName)))
                    return `<span class="fw-bold">${firstName}</span>, it looks like we need your SSN to confirm your identity`
                } else {
                    // Individual applicant
                    return 'It looks like we need your SSN to confirm your identity'
                }
            },
        },
        methods: {
            onSubmit: async function () {
                if (this.submitting) {
                    return
                }
                try {
                    this.submitting = true

                    const isValid = await this.$refs.ssnVerificationForm.$refs.observer.validate()
                    if (!isValid) {
                        // scroll validation errors into view
                        const el = document.querySelector('.is-invalid:first-of-type')
                        el?.scrollIntoView()
                        this.submitting = false
                        return
                    }

                    const response = await postUpdateApplicantFields({ ssn: this.ssn }, this.isCoApplicant)
                    assert(response.data.success, 'Error updating applicant SSN')
                    await this.$router.push(getNextRoute(this.$router))
                } catch (e) {
                    this.errorText = ApiErrorHandler(e)
                } finally {
                    this.submitting = false
                }
            },
        },
    }
</script>
