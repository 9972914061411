<template>
    <onboarding-layout
        :loading="loading"
        :loading-title="loadingTitle"
        :error-text="errorText"
    >
        <h5
            v-if="!hasTaxFreeVariant"
            class="fw-light text-center"
            v-html="$t('pages.origination.preQualification.title')"
        />
        <div
            class="mt-3"
            v-if="preQualOffers && preQualOffers.length > 0"
        >
            <tax-state-prequal-info-options
                v-if="hasTaxFreeVariant"
                :pre-qual-terms="selectedPreQualOffer"
                :pre-qual-offers="preQualOffers"
                :tax-free-offer-idx="taxFreeVariantIdx"
                :loan-application-date="loanApplicationDate"
                :choose-pre-qual-terms="choosePreQualTermsAndOptionallyUpdateStatus"
                :on-submit="onSubmit"
                :is-confirmed-first-lien-position="isConfirmedFirstLienPosition"
                @prequalTermsSelection="onPrequalTermsSelection"
            />
            <pre-qual-info-options
                v-else
                :pre-qual-terms="selectedPreQualOffer"
                :pre-qual-offers="preQualOffers"
                :loan-application-date="loanApplicationDate"
                :choose-pre-qual-terms="choosePreQualTermsAndOptionallyUpdateStatus"
                :on-submit="onSubmit"
                :is-confirmed-first-lien-position="isConfirmedFirstLienPosition"
                @prequalTermsSelection="onPrequalTermsSelection"
            />
        </div>
        <pre-qual-terms
            class="d-none d-md-block"
            :on-get-document="getDocument"
            :is-confirmed-first-lien-position="isConfirmedFirstLienPosition"
        />
        <template #sidebar>
            <ul class="list-unstyled list-row">
                <rate-comparison-graph-list-item
                    v-if="currentSelectedOffer"
                    :rate="currentSelectedOffer.apr"
                    :dti="dti"
                    :score="score"
                />
                <social-proof-list-item :loading="loading" />
            </ul>
            <no-credit-card-cost-list-item />

            <monthly-payment-list-item
                v-if="currentSelectedOffer && theoreticalPaymentStats"
                :line-size="currentSelectedOffer.lineSize"
                :apr="theoreticalPaymentStats.apr"
                :payment-size="theoreticalPaymentStats.firstPayment"
                :total-payments="theoreticalPaymentStats.totalPayments"
                :total-paid="theoreticalPaymentStats.totalPaid"
            />
            <fixed-monthly-payment-list-item
                v-if="currentSelectedOffer && theoreticalFixedPaymentStats"
                :theoretical-fixed-payment-stats="theoreticalFixedPaymentStats"
                :line-size="currentSelectedOffer.lineSize"
                :term-months-options="theoreticalTermMonthsOptions"
            />
            <feedback-list-item />
        </template>
    </onboarding-layout>
</template>

<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import { i18n } from '@/utils/i18n'
    import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { Flows, getNextRoute } from '@/flow/flowController'
    import originationMixin from '@/mixins/originationMixin'
    import { logger } from '@/utils/logger'
    import { RouteOption } from '@/flow/flowUtility'
    import { getDocumentForApplicantAndOpen } from '@/utils/document'
    import preQualificationMixin from '@/mixins/preQualificationMixin'
    import { sharedPagePaths } from '@/routes/sharedRoutes'
    import { EXTENDED_LINES_LINE_SIZE_THRESHOLD } from '@/services/homeApi'
    import MonthlyPaymentListItem from '@/components/onboarding/MonthlyPaymentListItem'
    import RateComparisonGraphListItem from '@/components/onboarding/RateComparisonGraphListItem'
    import SocialProofListItem from '@/components/onboarding/SocialProofListItem'
    import NoCreditCardCostListListItem from '@/components/onboarding/NoCreditCardCostListItem'
    import PreQualInfoOptions from '@/components/PreQualInfoOptions'
    import TaxStatePreQualInfoOptions from '@/components/TaxStatePreQualInfoOptions'
    import PreQualTerms from '@/components/PreQualTerms'
    import { LegalDocumentTypes } from '@/services/api'
    import { PreQualAction } from '../../mixins/preQualificationMixin'
    import FixedMonthlyPaymentListItem from '@/components/onboarding/FixedMonthlyPaymentListItem'
    import { experimentsMixin } from '@/mixins/experimentsMixin'
    import FeedbackListItem from '@/components/onboarding/FeedbackListItem'

    export default {
        components: {
            FeedbackListItem,
            FixedMonthlyPaymentListItem,
            'pre-qual-info-options': PreQualInfoOptions,
            'tax-state-prequal-info-options': TaxStatePreQualInfoOptions,
            'onboarding-layout': OnboardingLayout,
            'monthly-payment-list-item': MonthlyPaymentListItem,
            'rate-comparison-graph-list-item': RateComparisonGraphListItem,
            'social-proof-list-item': SocialProofListItem,
            'no-credit-card-cost-list-item': NoCreditCardCostListListItem,
            'pre-qual-terms': PreQualTerms,
        },
        mixins: [originationMixin, preQualificationMixin, experimentsMixin],
        data: function () {
            return {
                submitting: false,
                loading: true,
                loadingTitle: i18n.t('offerPreview.loading'),
                preQualOffers: null,
                isConfirmedFirstLienPosition: false,
            }
        },
        mounted: async function () {
            appSessionStorage.setItem(sessionStorageKey.currentFlow, Flows.mailerOrigination)
            try {
                const { preQualAction, underwritingResult } = await this.runAndGetHomeDataFetchAndPreQualificationOffer(true)

                // Please note these actions are also handled separately on origination/PreQualification with different behaviors.
                // If a preQualAction is added/removed here, please update origination/PreQualification accordingly
                switch (preQualAction) {
                    case PreQualAction.experianFail:
                    case PreQualAction.remedyOwnerNameMismatch:
                    case PreQualAction.offerAddCoApplicant:
                    case PreQualAction.homeOwnership:
                    case PreQualAction.homeIsLLC:
                    case PreQualAction.homeIsTrustAndRin:
                    case PreQualAction.homeIsTrustInNotSupportedState:
                    case PreQualAction.denied:
                    case PreQualAction.humanInvestigate:
                        logger.log(`PreQualAction: ${preQualAction}, continuing to personal info`)
                        appSessionStorage.setItem(sessionStorageKey.preQualificationFailureCode, 'humanInvestigate')
                        return await this.$router.push(getNextRoute(this.$router))
                    case PreQualAction.underwritingFail:
                        logger.error(`PreQualAction: ${preQualAction}, continuing to personal info`)
                        appSessionStorage.setItem(sessionStorageKey.preQualificationFailureCode, 'humanInvestigate')
                        return await this.$router.push(getNextRoute(this.$router))
                    case PreQualAction.isIneligible:
                        logger.log('Loan application is ineligible')
                        appSessionStorage.setItem(sessionStorageKey.preQualificationFailureCode, 'humanInvestigate')
                        return await this.$router.push({ path: sharedPagePaths.THANKS, query: { reason: 'identity' } })
                    case PreQualAction.experianNotFound:
                        logger.log('Experian not found, sending to ssnVerification')
                        return await this.$router.replace(getNextRoute(this.$router, RouteOption.ssnVerification))
                    case PreQualAction.experianFrozen:
                        return await this.$router.replace(getNextRoute(this.$router, RouteOption.experianFrozen))
                    case PreQualAction.coApplicantExperianFrozen:
                        return await this.$router.replace(getNextRoute(this.$router, RouteOption.coApplicantExperianFrozen))
                    case PreQualAction.coApplicantExperianNotFound:
                        return await this.$router.replace(getNextRoute(this.$router, RouteOption.coApplicantSsnVerification))
                    case PreQualAction.showPrequal:
                        logger.log('Experian data fetch successful, returning preQual offer action from backend')
                        await this.setVariablesFromUnderwritingResult(underwritingResult)
                        return await this.handlePreQualActionShowPreQual()
                    case PreQualAction.blockRepeatApplicant:
                        logger.info('Applicant has a prior application, showing duplicate thanks page')
                        return await this.$router.push({ path: sharedPagePaths.THANKS, query: { reason: 'duplicate' } })
                    case PreQualAction.skipPrequal:
                        return await this.$router.push(getNextRoute(this.$router))
                    case PreQualAction.expired:
                        return await this.$router.push({ path: sharedPagePaths.THANKS, query: { reason: 'expired' } })
                    case PreQualAction.blockedApplication:
                        logger.info(`Redirecting to thanks page due to application being blocked`)
                        return await this.$router.replace({ path: sharedPagePaths.THANKS, query: { reason: 'blockedApplication' } })
                    default:
                        logger.error(`Unexpected PreQualAction in mailer co-origination flow: ${PreQualAction}`)
                        return await this.$router.replace({ path: sharedPagePaths.THANKS, query: { reason: 'review' } })
                }
            } catch (e) {
                this.errorText = ApiErrorHandler(e)
            }
        },
        computed: {
            isExtendedLinesOffer: function () {
                return this.preQualOffers[0]?.lineSize > EXTENDED_LINES_LINE_SIZE_THRESHOLD
            },
            taxFreeVariantIdx: function () {
                return this.preQualOffers?.findIndex((offer) => offer.policyName.includes('TaxFreeVariant'))
            },
            hasTaxFreeVariant: function () {
                return this.taxFreeVariantIdx >= 0
            },
        },
        methods: {
            // this function is mirrored in origination/PreQualification, the only difference is handling of applicants who have already viewed PQ offer
            handlePreQualActionShowPreQual: async function () {
                this.currentSelectedOffer = this.preQualOffers[0]
                // fetch pre-qual theoretical stats
                await this.fetchTheoreticalPaymentStats(this.currentSelectedOffer.apr, this.currentSelectedOffer.lineSize)

                // These are the dynamically generated legal docs the applicant can view in the PreQualTerms component.
                const docsToGenerate = [LegalDocumentTypes.earlyHELOCDisclosure, LegalDocumentTypes.pricingAndTerms, LegalDocumentTypes.propertyValuation, LegalDocumentTypes.creditScoreDisclosure]
                // Purposefully not awaiting the results here because these docs are generated for
                // our record-keeping purposes and not strictly required for the UI
                // noinspection ES6MissingAwait
                this.tryGenerateAndSaveLegalDocuments(docsToGenerate)

                this.isConfirmedFirstLienPosition = !!appSessionStorage.getItem(sessionStorageKey.isFirstLienPosition)
                this.$logEvent('view_pre_qualification')
                this.loading = false
            },
            onSubmit: async function (preQualTerms) {
                this.$logEvent('click_button_submit_chosen_prequal_offer', { preQualTerms })
                if (this.loading) {
                    logger.log('Already submitting, ignoring button click')
                    return
                }
                this.loading = true

                try {
                    await this.choosePreQualTermsAndOptionallyUpdateStatus(preQualTerms, true)
                    return await this.$router.push(getNextRoute(this.$router))
                } catch (e) {
                    this.errorText = ApiErrorHandler(e)
                }
                this.loading = false
            },
            getDocument: async function (docType) {
                try {
                    this.loadingTitle = 'Loading Document...'
                    this.loading = true
                    this.$logEvent('click_link_get_document', { docType })
                    await getDocumentForApplicantAndOpen(docType)
                } catch (e) {
                    this.errorText = ApiErrorHandler(e, 'failed to open pdfs document')
                }
                this.loading = false
                this.loadingTitle = i18n.t('offerPreview.loading')
            },
        },
    }
</script>
